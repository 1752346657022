import $ from 'jquery';
window.$ = window.jQuery = $;
import('owl.carousel').then(() => {
    const $carousel = $('.owl-carousel-videos');
    const $carousels = [];

    $carousel.each(function () {
        var $carouselEl = $(this);
        var loop = $carouselEl.data('loop') ? $carouselEl.data('loop') : false;
        $carouselEl.owlCarousel({
            loop: loop,
            margin: 10,
            nav: true,
            dots: true,
            items: 1,
            autoWidth:true,
            center: true,
            rewind: false,
            smartSpeed: 250,
            fluidSpeed: 250,
            navSpeed: 250,
            dotsSpeed: 250,
            dragEndSpeed: 250,
            video: true,
            onInitialized: function () {
                $carouselEl.find('.owl-item.center').addClass('center-active');
            },
            onDrag: function () {
                $carouselEl.find('.owl-item').removeClass('center-active')
                $carouselEl.find('.owl-item').addClass('active');
            },
            //slideTransition: 'linear'
        });
        $carouselEl.on('translated.owl.carousel', function(event) {
            $carouselEl.find('.owl-item').removeClass('center-active');
            $carouselEl.find('.owl-item.center').addClass('center-active');
            if($carouselEl.find('.owl-item.center-active video') && $carouselEl.find('.owl-item.center-active video').get(0)) {
                $carouselEl.find('.owl-item.center-active video').get(0).play();
            }
            if($carouselEl.find('.owl-item.center-active video') && $carouselEl.find('.owl-item.center-active iframe')) {
                var $centerItem = $carouselEl.find('.owl-item.center-active .owl-carousel-videos-item');
                var $centerItemFrame = $centerItem.find('iframe');
                if ($centerItemFrame.length) {
                    var src = $centerItemFrame.attr('src');
                    if (!src.includes('autoplay=1')) {
                        var autoplaySrc = src + (src.indexOf('?') > -1 ? '&' : '?') + 'autoplay=1';
                        $centerItemFrame.attr('src', autoplaySrc);

                        let clone = $('.owl-item .owl-carousel-videos-item[data-pos='+$centerItem.data('pos')+'] iframe');
                        clone.attr('src', autoplaySrc);
                    }
                }
            }
        })

        $carouselEl.on('click', '.owl-item', function() {
            var index = $(this).find('.owl-carousel-videos-item').data('pos');
            $carouselEl.trigger('to.owl.carousel', [index, 300, true]);
        });

        $carousels.push($carouselEl);
    });

    //First click to start video
    $(window).on('click', function () {
        $($carousels).each(function () {
            if($(this).find('.owl-item.center-active video') && $(this).find('.owl-item.center-active video').get(0)) {
                $(this).find('.owl-item.center-active video').get(0).play();
            }
        })

        $(window).off('click');
    });
});


