document.addEventListener("DOMContentLoaded", () => {

    let klarConfig = {
        acceptAll: true,
        services: [
            {
                // In GTM, you should define a custom event trigger named `klaro-google-analytics-accepted` which should trigger the Google Analytics integration.
                name: 'google-analytics',
                purposes: ['marketing'],
                cookies: [
                    /^_ga(_.*)?/ // we delete the Google Analytics cookies if the user declines its use
                ],
            }
        ],
        translations: {
            fr: {
                /*
                You can specify a language-specific link to your privacy policy here.
                */
                privacyPolicyUrl: '/mentions-legales',
                consentNotice: {
                    description: 'Bonjour ! Pouvons-nous activer des services supplémentaires pour le marketing ? Vous pourrez toujours modifier ou retirer votre consentement plus tard.',
                },
                consentModal: {
                    description:
                        'Vous pouvez évaluer et personnaliser les services que nous aimerions utiliser sur ce site. C\'est vous qui décidez ! Activez ou désactivez les services comme bon vous semble.',
                },
            },
        },

    }

    // we assign the Klaro module to the window, so that we can access it in JS
    window.klaro = klaro;
    window.klaroConfig = klarConfig;

    setTimeout(function () {
        // we set up Klaro with the config
        klaro.setup(klarConfig);
    }, 2000);


    // Onclick event to show the consent modal
    document.getElementById('show-consent').addEventListener('click', function(event) {
        event.preventDefault();
        klaro.show();
    });
});


