import * as bootstrap from 'bootstrap';
import $ from 'jquery';

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class App {
    constructor() {
        console.log("%cGenesii with ❤", "color: #9ed6a8; padding: 5px 0px 1px; border-bottom:2px solid #9ed6a8;");
        this.init();
    }

    init() {
        // Initialiser l'animation GSAP
        this.initGSAPAnimation();

        this.initIframe();

        function smoothScroll(targetId) {
            var $targetElement = $('#' + targetId);
            console.log($targetElement);

            if ($targetElement.length) {
                var offsetPosition = $targetElement.offset().top - 100;

                $('html, body').animate({
                    scrollTop: offsetPosition
                }, 250, function() {
                    // Optionally remove the anchor from the URL
                    history.replaceState(null, null, window.location.pathname + window.location.search);
                });
            }
        }

        // Handle initial page load with anchor
        if (window.location.hash) {
            var anchorId = window.location.hash.substring(1);
            setTimeout(function() {
                smoothScroll(anchorId);
            }, 1000);
        }

        // Handle clicks on anchor links within the same page
        $('a[href^="#"]').on('click', function(event) {
            event.preventDefault();

            var targetId = $(this).attr('href').substring(1);
            smoothScroll(targetId);
        });
    }
    initGSAPAnimation() {
        const bodyHeight = document.body.scrollHeight;
        const viewportHeight = window.innerHeight;
        const scrollDistance = bodyHeight - viewportHeight;
        const maxWidth = $('.header-container').width();

        gsap.to("header", {
            scrollTrigger: {
                trigger: "body",
                start: "top top",
                end: `${scrollDistance}px top`,
                scrub: true,
            },
            maxWidth: `${maxWidth}px`,
            ease: "none"
        });

        this.triggerAppearAnimations();
    }

    triggerAppearAnimations() {
        const anims = document.querySelectorAll('.title-h1, .title-h1 span, .text-1, .animate-opacity, .translate-down, .translate-right, .translate-up, .translate-left, .animate-scale');
        anims.forEach(section => {
            gsap.to(section,
                {
                    opacity: 1,
                    y: 0,
                    x: 0,
                    scale: 1,
                    duration: 0.4,
                    ease: "power2.out",
                    scrollTrigger: {
                        trigger: section,
                        start: "top 85%", // Déclenche l'animation lorsque le haut de l'élément entre dans le viewport
                        toggleActions: "play none none none",
                        onEnter: () => {
                        }
                    }
                }
            );
        });

        const numbers = document.querySelectorAll('.lame-chiffres-number');
        const numbersParent = document.querySelector('.lame-chiffres-wrapper');

        numbers.forEach(number => {
            gsap.to(number, {
                innerHTML: $(number).data('chiffre'),
                duration: 2,
                ease: 'power5.out',
                snap: { innerHTML: 1 },
                onUpdate: function () {
                    number.innerHTML = Math.round(number.innerHTML);
                },
                scrollTrigger: {
                    trigger: numbersParent,
                    start: "top 85%",
                    toggleActions: "play none none none",
                }
            });
        });
    }

    initIframe() {
        $('.lame-projet-carousel-miniature').on('click', function () {
            $('.lame-projet-carousel-miniature').removeClass('active');
            $('.lame-projet-carousel-item iframe').attr('src', $(this).data('iframe'));
            $('.lame-projet-carousel-item-text').html($(this).data('text'));
            $(this).addClass('active');
        })
    }

}
