import App from "./js/App";
import './js/OwlCarousel';
import './js/Cookies'
import $ from "jquery";
document.addEventListener("DOMContentLoaded", () => {
    //window.scrollTo(0, 0);
    new App();

    $(".draggable, .star, .group-star").draggable();
});

